import React, { useEffect, useState } from "react";
import TabWrapper from "../../TabWrapper";
import { Box, Divider, Tab, Typography } from "@mui/material";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";

import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import MainSection from "./MainSection";
import UnsavedChangesModal from "../../components/UnsavedChangesModal/UnsavedChangesModal";
import {
  cleanValuesForRequest,
  findZonesSettingsDifferences,
  mapZonesArrayToResponse,
} from "../../helpers/findDifferences";
import ConfirmChangesModal from "../../components/ConfirmChangesModal/ConfirmChangesModal";
import DifferencesToShowInModal from "./DifferencesToShowInModal";
import SetPointsSection from "./SetPointSections/SetPointsSection";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useGetInstallationStateQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../store/services/installation";
import {
  IInstallationSettingsByIdResponse,
  IInstallationZoneOperation,
  ISetInstallationSettings,
  ISetOperationZonesParams,
  InstallationComissioningZoneCircuitTypeEnum,
  InstallationZoneOperationModeEnum,
} from "../../../../../../store/services/models/installations/installationSettings";
import {
  IInstallationBaseValue,
  ZoneOperationsKey,
} from "../../../../../../store/services/models/installations/installationsCommon";
import { useAppDispatch } from "../../../../../../store";
import { ITabData } from "../../../../../../components/TabsNavigation/TabsNavigation";
import ZonesTabs from "../../components/ZonesTabs/ZonesTabs";
import PrivateRoute from "../../../../../../components/PrivateRoute/PrivateRoute";
import { HomeSmile } from "untitledui-js-base";
import NotificationModal from "../../components/NotificationModal/NotificationModal";
import SettingsSwitcher from "../../components/SettingsSwitcher/SettingsSwitcher";

export interface IInstallationZoneOperationAsArrayItem
  extends IInstallationZoneOperation {
  isZoneActivated?: boolean;
  zonePropertyName?: string;
  zoneNumber?: number;
  mode?: InstallationZoneOperationModeEnum;
  minFlowHeatTemp?: IInstallationBaseValue;
  maxFlowHeatTemp?: IInstallationBaseValue;
  minFlowCoolTemp?: IInstallationBaseValue;
  maxFlowCoolTemp?: IInstallationBaseValue;
  minIndoorHeatTemp?: IInstallationBaseValue;
  maxIndoorHeatTemp?: IInstallationBaseValue;
  minIndoorCoolTemp?: IInstallationBaseValue;
  maxIndoorCoolTemp?: IInstallationBaseValue;
  circuitType?: InstallationComissioningZoneCircuitTypeEnum;
}

export interface IInstallationsZoneOperationStateAsArrayItem {
  currentRoomTemp: IInstallationBaseValue;
  zonePropertyName?: string;
}

export default function Zones() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const {
    data: installationState,
    isFetching: isFetchingState,
    isLoading: isLoadingState,
    refetch: refetchState,
  } = useGetInstallationStateQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const [setInstallationSettings] = useSetInstallationSettingsMutation();

  const [zonesCount, setZonesCount] = useState<number>(0);

  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);
  const [zonesSettingsAsArray, setZonesSettingsAsArray] = useState<
    Array<IInstallationZoneOperationAsArrayItem>
  >([]);
  const [initialZonesSettingsAsArray, setInitialZonesSettingsAsArray] =
    useState<Array<IInstallationZoneOperationAsArrayItem>>([]);
  const [zonesStateAsArray, setZonesStateAsArray] = useState<
    Array<IInstallationsZoneOperationStateAsArrayItem>
  >([]);
  const [isSomethingChanged, setIsSomethingChanged] = useState(false);

  const [differencesToShow, setDifferencesToShow] = useState<Array<any>>([]);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);

  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [zonesRoutes, setZonesRoutes] = useState<Array<ITabData>>([]);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const [isSecondZoneActive, setIsSecondZoneActive] = useState<boolean>(true); //TODO!!! MOCK DATA? QUESTIONS

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      const zoneOperations = settingsParameters?.zoneOperations;
      const comissioningZones =
        settingsParameters.installerSettings?.commissioning?.zoneOperations;
      const extractZoneNumber = (zoneKey: string): number | null => {
        const match = zoneKey.match(/zone(\d+)Operations/);
        return match ? parseInt(match[1], 10) : null;
      };
      let mappedZones: Array<any> = [];
      if (zoneOperations) {
        mappedZones = Object.keys(zoneOperations).map((zoneKey) => {
          return {
            ...zoneOperations[zoneKey as ZoneOperationsKey],
            zonePropertyName: zoneKey,
            preferredRoomTemp: {
              ...zoneOperations[zoneKey as ZoneOperationsKey]
                ?.preferredRoomTemp,
              value: Number(
                zoneOperations[
                  zoneKey as ZoneOperationsKey
                ]?.preferredRoomTemp.value.toFixed()
              ),
            },
            zoneNumber: extractZoneNumber(zoneKey),
          };
        });
      }
      if (settingsParameters.zonesCount.value === 1) {
        mappedZones = mappedZones.filter((zone) => zone.zoneNumber !== 2);
      }

      if (mappedZones?.length && comissioningZones) {
        Object.keys(comissioningZones).map((zoneKey) => {
          mappedZones = mappedZones.map(
            (zone: IInstallationZoneOperationAsArrayItem) => {
              if (zone.zonePropertyName === zoneKey) {
                const currentZone =
                  comissioningZones[zoneKey as ZoneOperationsKey];
                return {
                  ...zone,
                  mode: currentZone?.mode,
                  minFlowHeatTemp: currentZone?.minFlowHeatTemp,
                  maxFlowHeatTemp: currentZone?.maxFlowHeatTemp,
                  minFlowCoolTemp: currentZone?.minFlowCoolTemp,
                  maxFlowCoolTemp: currentZone?.maxFlowCoolTemp,
                  minIndoorHeatTemp: currentZone?.minIndoorHeatTemp,
                  maxIndoorHeatTemp: currentZone?.maxIndoorHeatTemp,
                  minIndoorCoolTemp: currentZone?.minIndoorCoolTemp,
                  maxIndoorCoolTemp: currentZone?.maxIndoorCoolTemp,
                  circuitType: currentZone?.circuitType,
                };
              } else {
                return zone;
              }
            }
          );
        });
      }
      setZonesSettingsAsArray(JSON.parse(JSON.stringify(mappedZones)));
      setInitialZonesSettingsAsArray(JSON.parse(JSON.stringify(mappedZones)));
      setZonesCount(settings?.zonesCount.value || 0);

      let zonesTabs: Array<ITabData> = mappedZones.map((zone) => {
        return {
          href: `/portal/residential-installations/${id}/settings/installation-settings-zones/zone${zone.zoneNumber}${location.search}`,
          label: `${t("pages.installationSettings.zones.zoneTitle")} ${
            zone.zoneNumber
          }`,
        };
      });
      setZonesRoutes(zonesTabs);
      if (zonesTabs.length) {
        const baseUrl = `/portal/residential-installations/${id}/settings/installation-settings-zones/`;
        if (location.pathname.endsWith("/installation-settings-zones")) {
          navigate(`${baseUrl}zone1${location.search}`);
        }
      }
    }
  }, [settingsParameters]);

  useEffect(() => {
    if (installationState) {
      const zoneOperations = installationState.zoneOperations;
      let mappedZones: Array<any> = [];
      if (zoneOperations) {
        mappedZones = Object.keys(zoneOperations).map((zoneKey) => {
          return {
            currentRoomTemp:
              zoneOperations[zoneKey as ZoneOperationsKey]?.currentRoomTemp,
            zonePropertyName: zoneKey,
          };
        });
      }
      setZonesStateAsArray(mappedZones);
    }
  }, [installationState]);

  const checkForChanges = () => {
    if (
      JSON.stringify(zonesSettingsAsArray) !==
      JSON.stringify(initialZonesSettingsAsArray)
    ) {
      setIsSomethingChanged(true);
    } else {
      setIsSomethingChanged(false);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [zonesSettingsAsArray]);

  const updateZoneSetting = (index: number, field: string, value: any) => {
    const updatedZones = [...zonesSettingsAsArray];
    (updatedZones[index] as any)[field] = value;
    setZonesSettingsAsArray(updatedZones);
  };

  const updateNestedZoneSetting = (
    index: number,
    field: string,
    pointKey: string,
    subField: string,
    value: any
  ) => {
    const updatedZones = [...zonesSettingsAsArray];
    (updatedZones[index] as any)[field][pointKey][subField] = value;
    setZonesSettingsAsArray(updatedZones);
  };

  const onDiscardChanges = () => {
    setZonesSettingsAsArray(
      JSON.parse(JSON.stringify(initialZonesSettingsAsArray))
    );
  };

  const onSaveChanges = () => {
    const differences = findZonesSettingsDifferences(
      initialZonesSettingsAsArray,
      zonesSettingsAsArray
    );
    setDifferencesToShow(differences);
    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    const newZoneOperations: {
      [key: string]: Partial<ISetOperationZonesParams>;
    } = {};
    differencesToShow.forEach((zone) => {
      const zoneKey = zone.zonePropertyName as ZoneOperationsKey;

      if (zoneKey) {
        newZoneOperations[zoneKey] = cleanValuesForRequest(zone.differences);
      }
    });

    return {
      zoneOperations: newZoneOperations,
    };
  };

  const onConfirmSettigsChanges = () => {
    if (initialSettings) {
      const responseForCache = mapZonesArrayToResponse(
        zonesSettingsAsArray,
        initialSettings
      );
      setIsConfirmChangesModalOpen(false);
      const requestBody = createRequest();

      setInstallationSettings({
        ...(requestBody as ISetInstallationSettings),
        uuid: id!,
      })
        .unwrap()
        .then(() => {
          dispatch(
            installationApi.util.updateQueryData(
              "getInstallationSettings",
              { uuid: id!, isRealData: params.get("dummyData") === "false" },
              (draftSettings) => {
                Object.assign(draftSettings, responseForCache);
              }
            )
          );
          setIsNotificationModalOpen(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Typography
        sx={{
          ...theme.typography[".text-lg-semibold"],
          color: theme.colors.colorsTextTextPrimary,
          mb: theme.spacing.spacingXl,
          p: `${theme.spacing.spacingMd} ${theme.spacing.spacing4xl} 0`,
        }}
      >
        {t("pages.installationSettings.zones.mainTitle")}
      </Typography>
      {zonesRoutes?.length > 1 && (
        <Box sx={{ p: `0 ${theme.spacing.spacing4xl}` }}>
          <ZonesTabs tabs={zonesRoutes} />
        </Box>
      )}
      <Box sx={{ width: "100%", height: "100%" }}>
        <Routes>
          {zonesSettingsAsArray.map((zone, index) => {
            const currentZoneState = zonesStateAsArray.find(
              (zoneState) =>
                zoneState.zonePropertyName === zone.zonePropertyName
            );
            const modeValueToDisplay = {
              [InstallationZoneOperationModeEnum.HEATING]: t(
                "pages.installationSettings.zones.onlyHeating"
              ),
              [InstallationZoneOperationModeEnum.COOLING]: t(
                "pages.installationSettings.zones.onlyCooling"
              ),
              [InstallationZoneOperationModeEnum.BOTH]: t(
                "pages.installationSettings.zones.HeatingAndCooling"
              ),
              [InstallationZoneOperationModeEnum.NONE]: t(
                "pages.installationSettings.zones.none"
              ),
            };
            return (
              <Route
                key={`zone${zone.zoneNumber}`}
                path={`/zone${zone.zoneNumber}`}
                element={
                  <PrivateRoute
                    element={
                      <TabWrapper
                        onDiscardPress={onDiscardChanges}
                        onSaveChangesPress={onSaveChanges}
                        isDiscardDisabled={!isSomethingChanged}
                        isSaveDisabled={!isSomethingChanged}
                        topHeight={"170"}
                      >
                        {zonesCount > 0 &&
                          (index === 0 || (index === 1 && zonesCount > 1)) && (
                            <React.Fragment key={`zone${zone.zoneNumber}`}>
                              {/* {zone.zoneNumber! > 1 && (
                                <SettingsSwitcher
                                  label={`${t(
                                    "pages.installationSettings.zones.zoneTitle"
                                  )} - ${zone.zoneNumber}`}
                                  state={isSecondZoneActive}
                                  changeState={(val) =>
                                    setIsSecondZoneActive(val)
                                  }
                                  statusOn={`${t("actions.activate")}`}
                                  statusOff={`${t("actions.deactivate")}`}
                                />
                              )} */}
                              {(zone.zoneNumber === 1 ||
                                (zone.zoneNumber === 2 &&
                                  isSecondZoneActive)) && (
                                <>
                                  <MainSection
                                    desiredRoomTemperature={
                                      +zone.preferredRoomTemp.value
                                    }
                                    setDesiredRoomTemperature={(val) => {
                                      updateZoneSetting(
                                        index,
                                        "preferredRoomTemp",
                                        {
                                          ...zone.preferredRoomTemp,
                                          value: Number(val),
                                        }
                                      );
                                    }}
                                    roomTemperature={
                                      Math.trunc(
                                        currentZoneState?.currentRoomTemp
                                          .value || 0
                                      ) + "°C" || ""
                                    }
                                    zoneNumber={`${zone.zoneNumber}`}
                                    mode={
                                      modeValueToDisplay[
                                        zone.mode as InstallationZoneOperationModeEnum
                                      ]
                                    }
                                  />
                                  {zone.mode !==
                                    InstallationZoneOperationModeEnum.NONE && (
                                    <Divider
                                      sx={{
                                        width: "100%",
                                        borderColor:
                                          theme.colors
                                            .colorsBorderBorderSecondary,
                                      }}
                                    />
                                  )}
                                  <SetPointsSection
                                    index={index}
                                    updateNestedZoneSetting={
                                      updateNestedZoneSetting
                                    }
                                    updateZoneSetting={updateZoneSetting}
                                    zone={zone}
                                    desiredRoomTemperature={
                                      +zone.preferredRoomTemp.value
                                    }
                                    setDesiredRoomTemperature={(val) => {
                                      updateZoneSetting(
                                        index,
                                        "preferredRoomTemp",
                                        {
                                          ...zone.preferredRoomTemp,
                                          value: Number(val),
                                        }
                                      );
                                    }}
                                    roomTemperature={
                                      Math.trunc(
                                        currentZoneState?.currentRoomTemp
                                          .value || 0
                                      ) + "°C" || ""
                                    }
                                  />
                                </>
                              )}
                            </React.Fragment>
                          )}
                        <UnsavedChangesModal
                          tabName={t(
                            "pages.installationSettings.tabsNames.zones"
                          )}
                          condition={isSomethingChanged}
                          discardChanges={onDiscardChanges}
                        />
                        {isConfirmChangesModalOpen && (
                          <ConfirmChangesModal
                            isOpen={isConfirmChangesModalOpen}
                            onCancel={() => setIsConfirmChangesModalOpen(false)}
                            onOk={() => onConfirmSettigsChanges()}
                            page={`${t(
                              "pages.installationSettings.tabsNames.zones"
                            )}`}
                            block={`${t(
                              "pages.installationSettings.tabsNames.zones"
                            )}`}
                            icon={
                              <HomeSmile
                                size="24"
                                style={{
                                  color:
                                    theme.colors
                                      .colorsForegroundFgWarningPrimary,
                                }}
                                strokeWidth={2}
                              />
                            }
                          >
                            <DifferencesToShowInModal
                              differencesToShow={differencesToShow}
                            />
                          </ConfirmChangesModal>
                        )}
                        {isNotificationModalOpen && (
                          <NotificationModal
                            isOpen={isNotificationModalOpen}
                            onOkPress={() => setIsNotificationModalOpen(false)}
                            icon={
                              <HomeSmile
                                size="24"
                                style={{
                                  color:
                                    theme.colors
                                      .colorsForegroundFgWarningPrimary,
                                }}
                                strokeWidth={2}
                              />
                            }
                          />
                        )}
                      </TabWrapper>
                    }
                    rolesToCheck={["INSTALLATION_READ"]}
                  />
                }
              />
            );
          })}
        </Routes>
      </Box>
    </Box>
    // <TabWrapper
    // onDiscardPress={onDiscardChanges}
    // onSaveChangesPress={onSaveChanges}
    // isDiscardDisabled={!isSomethingChanged}
    // isSaveDisabled={!isSomethingChanged}
    // >
    //   <MainTitle title={t("pages.installationSettings.zones.mainTitle")} />
    //   {zonesSettingsAsArray.map((zone, index) => {
    // const currentZoneState = zonesStateAsArray.find(
    //   (zoneState) => zoneState.zonePropertyName === zone.zonePropertyName
    // );

    // const modeValueToDisplay = {
    //   [InstallationZoneOperationModeEnum.HEATING]: t(
    //     "pages.installationSettings.zones.Heating"
    //   ),
    //   [InstallationZoneOperationModeEnum.COOLING]: t(
    //     "pages.installationSettings.zones.Cooling"
    //   ),
    //   [InstallationZoneOperationModeEnum.BOTH]: t(
    //     "pages.installationSettings.zones.HeatingAndCooling"
    //   ),
    //   [InstallationZoneOperationModeEnum.NONE]: t(
    //     "pages.installationSettings.zones.disabled"
    //   ),
    // };

    // return (
    // zonesCount > 0 &&
    // (index === 0 || (index === 1 && zonesCount > 1)) && (
    //   <React.Fragment key={`zone${zone.zoneNumber}`}>
    //     {zone.zoneNumber! > 1 && (
    //       <Divider
    //         sx={{
    //           width: "100%",
    //           borderColor: theme.colors.colorsBorderBorderSecondary,
    //         }}
    //       />
    //     )}
    //     <MainSection
    //       desiredRoomTemperature={+zone.preferredRoomTemp.value}
    //       setDesiredRoomTemperature={(val) => {
    //         updateZoneSetting(index, "preferredRoomTemp", {
    //           ...zone.preferredRoomTemp,
    //           value: Number(val),
    //         });
    //       }}
    //       roomTemperature={
    //         Math.trunc(currentZoneState?.currentRoomTemp.value || 0) +
    //           "°C" || ""
    //       }
    //       zoneNumber={`${zone.zoneNumber}`}
    //       mode={
    //         modeValueToDisplay[
    //           zone.mode as InstallationZoneOperationModeEnum
    //         ]
    //       }
    //     />
    //     {zone.mode !== InstallationZoneOperationModeEnum.NONE && (
    //       <Divider
    //         sx={{
    //           width: "100%",
    //           borderColor: theme.colors.colorsBorderBorderSecondary,
    //         }}
    //       />
    //     )}
    //     <SetPointsSection
    //       index={index}
    //       updateNestedZoneSetting={updateNestedZoneSetting}
    //       updateZoneSetting={updateZoneSetting}
    //       zone={zone}
    //     />
    //   </React.Fragment>
    // )
    // );
    //   })}
    // <UnsavedChangesModal
    //   tabName={t("pages.installationSettings.tabsNames.zones")}
    //   condition={isSomethingChanged}
    // />
    // {isConfirmChangesModalOpen && (
    //   <ConfirmChangesModal
    //     isOpen={isConfirmChangesModalOpen}
    //     onCancel={() => setIsConfirmChangesModalOpen(false)}
    //     onOk={() => onConfirmSettigsChanges()}
    //     page={`${t("pages.installationSettings.tabsNames.zones")}`}
    //   >
    //     <DifferencesToShowInModal differencesToShow={differencesToShow} />
    //   </ConfirmChangesModal>
    // )}
    // </TabWrapper>
  );
}
