import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
// import { RootState } from "..";
import { redirect } from "../reducers/authSlice";

type ConstraintType = "NotNull" | "Pattern";

// const navigate = useNavigate();
// const authApi = new AuthenticationAPI(navigate);

export type ServerErrorType = {
  status: 400 | 401 | 409 | 500;
  data:
    | {
        message: string;
      }
    | {
        code: string;
        message: string;
        fieldErrors: Array<{
          field: string;
          constraint: ConstraintType;
          message: string;
        }>;
      };
};

const getBaseQuery = fetchBaseQuery({
  baseUrl: "https://qa-api.cloud.energysave.se/api/v1",
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests

    // const token = (getState() as RootState).authSlice.user?.token;
    const token = window.localStorage.getItem("auth_token");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await getBaseQuery(args, api, extraOptions);
  const token = window.localStorage.getItem("auth_token");
  if (!token) {
    api.dispatch(redirect("/login"));
  }

  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await getBaseQuery(
      "/users/token/new",
      api,
      extraOptions
    );

    if ((refreshResult as any).token) {
      // store the new token in the store or wherever you keep it
      // api.dispatch(updateToken((refreshResult as any).token));
      window.localStorage.setItem("auth_token", (refreshResult as any).token);
      // retry the initial query
      result = await getBaseQuery(args, api, extraOptions);
    } else {
      // refresh failed - do something like redirect to login or show a "retry" button
      // api.dispatch(logout());
      window.localStorage.removeItem("auth_token");
      const currentPath = window.location.pathname + window.location.search;

      if (currentPath !== "/login" && currentPath !== "/") {
        window.localStorage.setItem("pre_auth_path", currentPath);
      }
      api.dispatch(redirect("/login"));
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["InstallationState", "InstallationSettings", "InstallationDetails"],
  endpoints: () => ({}),
});
