import { api } from "./api";
import { IInstallationDetails } from "./models/installations/installationsCommon";
import {
  IInstallationSettingsByIdResponse,
  ISetInstallationSettings,
} from "./models/installations/installationSettings";
import { IInstallationStateByIdResponse } from "./models/installations/installationState";

type GetInstallationStateParams = {
  uuid: string;
  isRealData?: boolean;
};

export type GetInstallationSettingsParams = {
  uuid: string;
  isRealData?: boolean;
};

export type GetInstallationRuntimeHistoryParams = {
  installationUuid: string;
  startDateTime: string;
  endDateTime?: string;
  param: Array<string>;
};

export interface ISetInstallationDetails {
  name: string;
  location: string;
}

export const installationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInstallationState: build.query<
      IInstallationStateByIdResponse,
      GetInstallationStateParams
    >({
      query: ({ uuid, isRealData }) => ({
        url: isRealData
          ? `installations/${uuid}/state?dummyData=false`
          : `installations/${uuid}/state`,
      }),
      providesTags: ["InstallationState"],
    }),
    getInstallationSettings: build.query<
      IInstallationSettingsByIdResponse,
      GetInstallationSettingsParams
    >({
      query: ({ uuid, isRealData }) => ({
        url: isRealData
          ? `installations/${uuid}/settings?dummyData=false`
          : `installations/${uuid}/settings`,
      }),
      providesTags: ["InstallationSettings"],
    }),
    setInstallationSettings: build.mutation<
      IInstallationSettingsByIdResponse,
      Partial<ISetInstallationSettings> & { uuid: string }
    >({
      query: ({ uuid, ...settings }) => ({
        url: `installations/${uuid}/settings`,
        method: "PATCH",
        headers: {
          // "content-type": "application/settings+json", //TODO!!! Condition (roles) for changing content-type???
          "content-type": "application/configuration-settings+json",
        },
        body: JSON.stringify(settings),
      }),
      // invalidatesTags: ['InstallationSettings', 'InstallationState'],
    }),

    getInstallationRuntimeHistory: build.query<
      any,
      GetInstallationRuntimeHistoryParams
    >({
      query: ({ installationUuid, startDateTime, endDateTime, param }) => ({
        url: `installations/${installationUuid}/runtimeHistory?startDateTime=${startDateTime}&endDateTime=${endDateTime}&param=${param.join(
          "&param="
        )}`,
        responseHandler: (response) => response.text(),
        headers: {
          Accept: "text/csv",
        },
      }),
      // providesTags: ["InstallationSettings"],
    }),

    getInstallationDetails: build.query<
      IInstallationDetails,
      { installationUuid: string }
    >({
      query: ({ installationUuid }) => ({
        url: `installations/${installationUuid}`,
      }),
      providesTags: ["InstallationDetails"],
    }),

    setInstallationDetails: build.mutation<
      IInstallationSettingsByIdResponse,
      ISetInstallationDetails & { uuid: string }
    >({
      query: ({ uuid, ...details }) => ({
        url: `installations/${uuid}`,
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(details),
      }),
    }),
  }),
});

export const {
  useGetInstallationStateQuery,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
  useGetInstallationRuntimeHistoryQuery,
  useGetInstallationDetailsQuery,
  useLazyGetInstallationRuntimeHistoryQuery,
  useSetInstallationDetailsMutation,
  endpoints: {
    getInstallationState,
    getInstallationSettings,
    setInstallationSettings,
    getInstallationRuntimeHistory,
    getInstallationDetails,
    setInstallationDetails
  },
} = installationApi;
