import { Box, SxProps, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabsNavigation, {
  ITabData,
} from "../../../components/TabsNavigation/TabsNavigation";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import RealTimeData from "./RealTimeData/RealTimeData";
import useTheme from "../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { FleetManagementAPI } from "../../../api/FleetManagementAPI";
import { Status } from "../../../api/responses/fleetManagementResponses/FleetManagementResponse";
import { ArrowLeft } from "untitledui-js-base";
import SettingsPage from "./SettingsPage/SettingsPage";
import AnalyticsPage from "./AnalyticsPage/AnalyticsPage";
import InfoDetailsPage from "./InfoDetailsPage/InfoDetailsPage";

export default function ResidentialInstallationDetailPage() {
  const theme = useTheme();
  const { typography, spacing, colors } = theme;
  const labelBaseStyles: SxProps = {
    ...typography[".text-xs-medium"],
    height: "22px",
    padding: `${spacing.spacingXxs} ${spacing.spacingMd} ${spacing.spacingXxs} ${spacing.spacingSm}`,
    borderRadius: "10px",
    border: `1px solid ${colors.componentColorsUtilityGrayUtilityGray600}`,
    color: colors.componentColorsUtilityGrayUtilityGray600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const dotStyles: SxProps = {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: colors.componentColorsUtilityBrandUtilityBrand600,
    mr: spacing.spacingSm,
  };

  const { t } = useTranslation("cloud_ui");

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const api = new FleetManagementAPI(navigate);

  const [tabs, setTabs] = useState<Array<ITabData>>();
  const [installationInfo, setInstallationInfo] = useState<{
    name: string;
    location: string;
    status: Status;
  }>();

  useEffect(() => {
    if (id) {
      api
        .getInstallationById(id)
        .then((res) => {
          setInstallationInfo({
            name: res.data.name,
            location: res.data.location,
            status: Status.ONLINE,
          });
        })
        .catch((err) => {});

      setTabs([
        {
          href: `/portal/residential-installations/${id}/real-time-data${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.realTimeData"),
        },
        {
          href: `/portal/residential-installations/${id}/settings${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.settings"),
        },
        {
          href: `/portal/residential-installations/${id}/alerts`,
          label: t("pages.residentialInstallation.details.tabs.alerts"),
        },
        {
          href: `/portal/residential-installations/${id}/analytics${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.analytics"),
        },
        {
          href: `/portal/residential-installations/${id}/info-details${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.infoDetails"),
        },
      ]);
    }
  }, [id]);

  return (
    <Box
      sx={{
        display: "flex",
        padding: `${theme.spacing.spacing2xl} ${theme.spacing.spacing2xl} 0`,
        flexDirection: "column",
        backgroundColor: theme.colors.colorsBackgroundBgPrimary,
        height: "calc(100vh - 64px)",
      }}
    >
      <Box sx={{ mb: theme.spacing.spacingXl, display: "flex" }}>
        <Box
          component={"button"}
          sx={{
            backgroundColor: "initial",
            height: "56px",
            width: "56px",
            border: `1px solid ${theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder}`,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: theme.colors.colorsBackgroundBgSecondaryHover,
            },
          }}
          onClick={() => navigate("/portal/residential-installations")}
        >
          <ArrowLeft
            style={{
              color:
                theme.colors
                  .componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
            }}
            size="18"
            strokeWidth={2}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: theme.spacing.spacingLg,
          }}
        >
          <Box
            sx={{
              mb: theme.spacing.spacingXs,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                ...theme.typography[".display-xs-semibold"],
                color: theme.colors.colorsTextTextPrimary,
                mr: theme.spacing.spacingLg,
              }}
            >
              {installationInfo?.name || ""}
            </Typography>
            {installationInfo?.status === Status.ONLINE ? (
              <Box
                sx={{
                  ...labelBaseStyles,
                  color: colors.componentColorsUtilityBrandUtilityBrand600,
                  borderColor:
                    colors.componentColorsUtilityBrandUtilityBrand600,
                }}
              >
                <Box
                  sx={{
                    ...dotStyles,
                  }}
                ></Box>
                {t("online_statuses.online")}
              </Box>
            ) : (
              <Box sx={{ ...labelBaseStyles }}>
                {t("online_statuses.offline")}
              </Box>
            )}
          </Box>
          <Typography
            sx={{
              ...theme.typography[".text-md-regular"],
              color: theme.colors.colorsTextTextTertiary,
            }}
          >
            {installationInfo?.location || ""}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mb: theme.spacing.spacingLg }}>
        <TabsNavigation tabs={tabs} />
      </Box>

      <Routes>
        <Route
          path="/real-time-data"
          element={
            <PrivateRoute
              element={<RealTimeData />}
              rolesToCheck={["INSTALLATION_READ"]}
            />
          }
        ></Route>
        <Route
          path="/settings/*"
          element={
            <PrivateRoute
              element={<SettingsPage />}
              rolesToCheck={["INSTALLATION_READ"]}
            />
          }
        ></Route>
        <Route
          path="/analytics"
          element={
            <PrivateRoute
              element={<AnalyticsPage />}
              rolesToCheck={["INSTALLATION_READ"]}
            />
          }
        ></Route>
        <Route
          path="/info-details"
          element={
            <PrivateRoute
              element={<InfoDetailsPage />}
              rolesToCheck={["INSTALLATION_READ"]}
            />
          }
        ></Route>
      </Routes>
    </Box>
  );
}
