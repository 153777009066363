import {
  Box,
  Container,
  Grid,
  SxProps,
  TableCell,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import EmptyInstallationPage from "./EmptyPage";
import {
  AlertOctagon,
  AlertTriangle,
  Building01,
  Phone01,
  SearchLG,
} from "untitledui-js-base";
import { FleetManagementAPI } from "../../api/FleetManagementAPI";
import { useLocation, useNavigate } from "react-router";
import SortableTable, { Column } from "../../components/Grid/SortableTable";
import InstallationsListItemResponse, {
  AlertType,
  IInstallationAlert,
  Status,
} from "../../api/responses/fleetManagementResponses/FleetManagementResponse";
import { formatDate } from "../../util/DateUtil";
import { Button } from "../../components/Button/Button";
import queryString from "query-string";

export enum Filters {
  WITH_ALARMS = "WITH_ALARMS",
  WITH_WARNINGS = "WITH_WARNINGS",
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  WITH_USER_APP = "WITH_USER_APP",
  TYPE_DOMESTIC = "TYPE_DOMESTIC",
  TYPE_COMMERCIAL = "TYPE_COMMERCIAL",
}

export enum FiledsForSorting {
  connectivity_id = "connectivity_id",
  name = "name",
  installation_date = "installation_date",
  address = "address",
  installer = "installer",
  online_status = "online_status",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export default function ResidentialInstallationsPage() {
  const theme = useTheme();

  const { typography, spacing, colors } = theme;
  const labelBaseStyles: SxProps = {
    ...typography[".text-xs-medium"],
    height: "22px",
    padding: `${spacing.spacingXxs} ${spacing.spacingMd} ${spacing.spacingXxs} ${spacing.spacingSm}`,
    borderRadius: "10px",
    border: `1px solid ${colors.componentColorsUtilityGrayUtilityGray600}`,
    color: colors.componentColorsUtilityGrayUtilityGray600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const dotStyles: SxProps = {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: colors.componentColorsUtilityBrandUtilityBrand600,
    mr: spacing.spacingSm,
  };

  const alertsBaseStyles: SxProps = {
    ...typography[".text-sm-medium"],
    height: "24px",
    maxWidth: "fit-content",
    display: "flex",
    alignItems: "center",
    padding: `${spacing.spacingXxs} ${spacing.spacingMd} ${spacing.spacingXxs} ${spacing.spacingMd}`,
    border: `1px solid ${colors.componentColorsUtilityErrorUtilityError200}`,
    borderRadius: "12px",
    backgroundColor: colors.componentColorsUtilityErrorUtilityError50,
    color: colors.componentColorsUtilityErrorUtilityError700,
  };

  const { t } = useTranslation("cloud_ui");
  const navigate = useNavigate();
  const location = useLocation();
  const api = new FleetManagementAPI(navigate);

  const initialQuery = queryString.parse(location.search);

  const parseArrayFromQuery = (queryParam: any): string[] => {
    if (Array.isArray(queryParam)) return queryParam;
    if (typeof queryParam === "string") return queryParam.split(",");
    return [];
  };

  const [filterData, setFilterData] = useState<Array<string>>(
    parseArrayFromQuery(initialQuery.filters).length
      ? parseArrayFromQuery(initialQuery.filters)
      : [Filters.TYPE_DOMESTIC]
  );
  const [sortingData, setSortingData] = useState<{
    fieldName: FiledsForSorting;
    order: SortOrder;
  }>({
    fieldName:
      (initialQuery.sortField as FiledsForSorting) || FiledsForSorting.name,
    order: (initialQuery.sortOrder as SortOrder) || SortOrder.asc,
  });
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [page, setPage] = useState<number>(
    parseInt(initialQuery.page as string) || 0
  );
  const [totalPages, setTotalPages] = useState<number>();
  const [totalCount, setTotalCount] = useState<number>();
  const [searchValue, setSearchValue] = useState<string>(
    (initialQuery.searchValue as string) || ""
  );
  const [stringForSearch, setStringForSearch] = useState<string>(
    (initialQuery.stringForSearch as string) || ""
  );

  useEffect(() => {
    getInstallations();
  }, [page, filterData, sortingData, stringForSearch]);

  useEffect(() => {
    const params = {
      page,
      filters: filterData.join(","),
      sortField: sortingData.fieldName,
      sortOrder: sortingData.order,
      searchValue,
      stringForSearch,
    };

    const queryStringParams = queryString.stringify(params);
    navigate(`?${queryStringParams}`, { replace: true });
  }, [page, filterData, sortingData, searchValue, stringForSearch]);

  const getInstallations = () => {
    api
      .getInstallations(
        {
          pageNum: page === 0 ? 0 : page - 1,
          pageSize: 10,
          search: stringForSearch,
        },
        {
          filter: filterData?.join(","),
          sortBy: `${sortingData.fieldName}:${sortingData.order}`,
        }
      )
      .then((value) => {
        setPage(value.data.pagination.currentPage + 1);
        setTotalPages(value.data.pagination.totalPages);
        setTotalCount(value.data.pagination.totalCount);
        renderTableRows(value.data.result);
      })
      .catch((err) => {});
  };

  const renderTableRows = (installations: InstallationsListItemResponse[]) => {
    const data = installations.map(
      (installation: InstallationsListItemResponse) => {
        const hasErrors = installation.alerts.some(
          (alert) => alert.type === AlertType.ERROR
        );
        const hasWarnings = installation.alerts.some(
          (alert) => alert.type === AlertType.WARNING
        );
        return {
          id: installation.uuid,
          connectivity_id: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                minHeight: "52px",
                display: "flex",
                alignItems: "center",
                color: theme.colors.colorsTextTextTertiary,
              }}
            >
              {installation.connectivitySettings?.connectivityId}
            </Typography>
          ),
          name: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-medium"],
                color: theme.colors.colorsTextTextPrimary,
              }}
            >
              {installation.name}
            </Typography>
          ),
          installation_date: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextQuaternary,
              }}
            >
              {formatDate(installation.createdAt)}
            </Typography>
          ),
          address: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextSecondary,
              }}
            >
              {installation.location}
            </Typography>
          ),
          installer: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextQuaternary,
              }}
            ></Typography>
          ),
          online_status: renderOnlineStatus(installation.onlineStatus),
          alerts: renderAlerts(installation.alerts),
          userApp: false && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Phone01
                size="20"
                strokeWidth={2}
                style={{ color: colors.colorsForegroundFgBrandPrimary }}
              />
            </Box>
          ),
          isRowHighlited: installation.alerts.length,
          highlightColor: hasErrors
            ? colors.colorsForegroundFgErrorPrimary
            : hasWarnings
            ? colors.colorsForegroundFgWarningPrimary
            : "initial",
        };
      }
    );
    setTableData(data);
  };

  const tableColumns: Column[] = [
    { id: "id", label: "", hidden: true },
    {
      id: FiledsForSorting.connectivity_id,
      label: t("pages.residentialInstallation.table.lables.connectivityId"),
    },
    {
      id: FiledsForSorting.name,
      label: t("pages.residentialInstallation.table.lables.name"),
    },
    {
      id: FiledsForSorting.installation_date,
      label: t("pages.residentialInstallation.table.lables.registrationDate"),
    },
    {
      id: FiledsForSorting.address,
      label: t("pages.residentialInstallation.table.lables.address"),
    },
    {
      id: FiledsForSorting.installer,
      label: t("pages.residentialInstallation.table.lables.installer"),
    },
    {
      id: FiledsForSorting.online_status,
      label: t("pages.residentialInstallation.table.lables.status"),
      width: 70,
      align: "center",
    },
    {
      id: "alerts",
      label: t("pages.residentialInstallation.table.lables.alerts"),
      disableSorting: true,
      width: 90,
      align: "center",
    },
    {
      id: "userApp",
      label: t("pages.residentialInstallation.table.lables.userApp"),
      disableSorting: true,
      width: 90,
    },
  ];

  const filters = [
    {
      label: t("pages.residentialInstallation.table.filters.viewAll"),
      value: "clear",
    },
    {
      label: t("pages.residentialInstallation.table.filters.alarms"),
      value: Filters.WITH_ALARMS,
    },
    {
      label: t("pages.residentialInstallation.table.filters.warnings"),
      value: Filters.WITH_WARNINGS,
    },
  ];

  const renderOnlineStatus = (status: Status) => {
    if (status === Status.ONLINE) {
      return (
        <Box
          sx={{
            ...labelBaseStyles,
            color: colors.componentColorsUtilityBrandUtilityBrand600,
            borderColor: colors.componentColorsUtilityBrandUtilityBrand600,
          }}
        >
          <Box
            sx={{
              ...dotStyles,
            }}
          ></Box>
          {t("online_statuses.online")}
        </Box>
      );
    } else {
      return (
        <Box sx={{ ...labelBaseStyles }}>{t("online_statuses.offline")}</Box>
      );
    }
  };

  const renderAlerts = (alerts: Array<IInstallationAlert>) => {
    const errorsCount = alerts.filter(
      (alert) => alert.type === AlertType.ERROR
    ).length;
    const warningsCount = alerts.filter(
      (alert) => alert.type === AlertType.WARNING
    ).length;

    return (
      <Box sx={{ display: "flex" }}>
        {errorsCount > 0 && (
          <Box sx={{ ...alertsBaseStyles, mr: spacing.spacingXs }}>
            <AlertOctagon
              size="12"
              style={{
                marginRight: theme.spacing.spacingXs,
                color: colors.componentColorsUtilityErrorUtilityError500,
              }}
              strokeWidth={2}
            />
            {errorsCount}
          </Box>
        )}
        {warningsCount > 0 && (
          <Box
            sx={{
              ...alertsBaseStyles,
              borderColor:
                colors.componentColorsUtilityWarningUtilityWarning200,
              backgroundColor:
                colors.componentColorsUtilityWarningUtilityWarning50,
              color: colors.componentColorsUtilityWarningUtilityWarning700,
            }}
          >
            <AlertTriangle
              size="12"
              style={{
                marginRight: theme.spacing.spacingXs,
                color: colors.componentColorsUtilityWarningUtilityWarning500,
              }}
              strokeWidth={2}
            />
            {warningsCount}
          </Box>
        )}
      </Box>
    );
  };

  const renderInfoTableHeadContent = () => {
    return (
      <TableCell colSpan={8} sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            height: "1px",
            left: "0",
            top: "59px",
            width: "100%",
            backgroundColor: colors.colorsBorderBorderSecondary,
          }}
        ></Box>
        <Grid container alignItems={"center"}>
          <Grid item xs={2} sx={{ mb: spacing.spacingLg }}>
            <Typography
              color={"primary"}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#101828",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "30px",
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
              }}
            >
              {t("pages.commercialInstallation.table.mainTitle")}{" "}
              <Typography
                sx={{
                  m: "0 10px",
                  fontSize: "20px",
                  color: "#667085",
                  fontWeight: 500,
                  lineHeight: "30px",
                }}
              >
                {totalCount}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          sx={{ mt: spacing.spacingLg, mb: "-6px" }}
        >
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                border: `1px solid ${colors.colorsBorderBorderPrimary}`,
                borderRadius: "8px",
                maxWidth: "fit-content",
              }}
            >
              {filters.map((filter, index) => (
                <Box
                  key={filter.label}
                  sx={{
                    ...typography[".text-sm-semibold"],
                    color: colors.colorsTextTextSecondary,
                    padding: `${spacing.spacingMd} ${spacing.spacingXl}`,
                    borderRadius:
                      index === 0
                        ? "8px 0 0 8px"
                        : index === filters.length - 1
                        ? "0 8px 8px 0"
                        : "0",
                    borderRight:
                      index !== filters.length - 1
                        ? `1px solid ${colors.colorsBorderBorderPrimary}`
                        : "none",
                    cursor: "pointer",
                    backgroundColor: filterData.includes(filter.value)
                      ? colors.colorsBackgroundBgActive
                      : "initial",
                    "&:hover": {
                      backgroundColor: colors.colorsBackgroundBgActive,
                    },
                  }}
                  onClick={() => onFilterChange(filter.value as Filters)}
                >
                  {filter.label}
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "320px",
                position: "relative",
                mr: spacing.spacingLg,
                input: {
                  boxSizing: "border-box",
                  height: "44px",
                  width: "100%",
                  padding: "0 15px 0 30px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  fontFamily: `"Inter", "Roboto", "Arial", sans-serif`,
                  fontSize: "1rem",
                  lineHeight: "1.4375em",
                  color: theme.colors.colorsTextTextPrimary,
                  "&::placeholder": {
                    color: theme.colors.colorsTextTextPlaceholder,
                    opacity: ".5",
                  },
                  "&:hover": {
                    borderColor: theme.colors.colorsTextTextPrimary,
                  },
                  "&:focus-visible": {
                    outline: "-webkit-focus-ring-color auto 1px",
                    outlineColor: "#6c6b6a",
                  },
                },
              }}
            >
              <SearchLG
                style={{
                  position: "absolute",
                  top: "14px",
                  left: "10px",
                  color: theme.colors.colorsTextTextPlaceholder,
                  opacity: ".5",
                }}
                size="16"
                strokeWidth={2}
              />
              <input
                value={searchValue}
                placeholder={`${t("common_placeholders.search")}`}
                onChange={onSearchChange}
              />
            </Box>
            <Button
              label={`${t("common_placeholders.search")}`}
              onClick={onSearchButtonClick}
            />
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const onSortClick = (columnId: string, direction: string) => {
    setSortingData({
      fieldName: columnId as FiledsForSorting,
      order: direction as SortOrder,
    });
  };

  const onFilterChange = (filterValue: Filters | string) => {
    switch (filterValue) {
      case "clear":
        setFilterData([Filters.TYPE_DOMESTIC]);
        break;
      case Filters.WITH_ALARMS:
        if (filterData.includes(Filters.WITH_ALARMS)) {
          setFilterData((prev) =>
            prev.filter((val) => val !== Filters.WITH_ALARMS)
          );
        } else {
          setFilterData((prev) => [...prev, Filters.WITH_ALARMS]);
        }
        break;
      case Filters.WITH_WARNINGS:
        if (filterData.includes(Filters.WITH_WARNINGS)) {
          setFilterData((prev) =>
            prev.filter((val) => val !== Filters.WITH_WARNINGS)
          );
        } else {
          setFilterData((prev) => [...prev, Filters.WITH_WARNINGS]);
        }
        break;
      default:
        break;
    }
  };

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const onSearchButtonClick = () => {
    setStringForSearch(searchValue?.trim());
  };

  const onRowClick = (row: any) => {
    navigate(`/portal/residential-installations/${row.id}/real-time-data`);
  };

  return (
    <Container
      style={{ marginTop: "2em", paddingBottom: theme.spacing.spacing5xl }}
    >
      <div style={{ marginBottom: "3em" }}>
        <Grid container alignItems={"center"}>
          <Grid item xs={6}>
            <Typography
              color={"primary.dark"}
              style={{ fontWeight: "500" }}
              variant={"h4"}
            >
              {t("pages.residentialInstallation.title")}
            </Typography>
          </Grid>
        </Grid>
      </div>

      {!tableData?.length ? (
        <EmptyInstallationPage
          icon={
            <Building01
              size="24"
              style={{
                color:
                  theme.colors
                    .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray,
                position: "relative",
                left: "3px",
                top: "3px",
              }}
              strokeWidth={2}
            />
          }
          title={`${t("pages.residentialInstallation.emptyStateTitle")}`}
          description={`${t(
            "pages.residentialInstallation.emptyStateDescription"
          )}`}
        />
      ) : (
        <SortableTable
          columns={tableColumns}
          rows={tableData}
          infoTableHeadContent={renderInfoTableHeadContent()}
          defaultSorting={(initialQuery?.sortField as any) || "name"}
          defaultOrderDirection={
            (initialQuery?.sortOrder as "asc" | "desc") || "asc"
          }
          onRowClick={onRowClick}
          onSortClick={onSortClick}
          paginationData={{
            page: page,
            totalCount: totalPages!,
            onPageChange: handleChangePage,
          }}
        />
      )}
    </Container>
  );
}
