import { Box, Checkbox, FormControlLabel, SxProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { Check } from "untitledui-js-base";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../components/Button/Button";

export interface ICheckboxesGroupProps {
  availableParameters: Array<{ value: string; label: string }>;
  selectedParameters: Array<string>;
  allAvailableParameters: Array<string>;
  setSelectedParameters: (updatedArray: Array<string>) => void;
  closeDropdown: () => void;
}

export default function CheckboxesGroup({
  availableParameters,
  selectedParameters,
  allAvailableParameters,
  setSelectedParameters,
  closeDropdown,
}: ICheckboxesGroupProps) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  const baseCheckboxStyles: SxProps = {
    height: "16px",
    width: "16px",
    borderRadius: "5px",
    border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
  };

  const selectedCheckboxStyles: SxProps = {
    ...baseCheckboxStyles,
    border: `1px solid ${theme.colors.colorsBackgroundBgBrandSolid}`,
    backgroundColor: theme.colors.colorsBackgroundBgBrandSolid,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const [selected, setSelected] = useState<Array<string>>(selectedParameters);

  useEffect(() => {
    setSelected(selectedParameters);
  }, [selectedParameters]);

  const arraysAreEqual = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "0",
        top: "130px",
        left: "-5px",
        width: "max-content",
        borderRadius: "5px",
        boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
        maxWidth: "calc(100vw - 260px)",
        overflowX: "auto",
        border: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
        pt: theme.spacing.spacingXs,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 66px)",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <Box
          key={"selectAll"}
          sx={{
            display: "flex",
            alignItems: "center",
            p: `${theme.spacing.spacingSm} ${theme.spacing.spacingXl}`,
            maxWidth: "240px",
            ".MuiTypography-root": {
              ...theme.typography[".text-sm-medium"],
              color: theme.colors.colorsTextTextSecondary,
            },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={arraysAreEqual(allAvailableParameters, selected)}
                onChange={() => {
                  setSelected(allAvailableParameters);
                }}
                icon={<Box sx={baseCheckboxStyles} />}
                checkedIcon={
                  <Box sx={selectedCheckboxStyles}>
                    <Check
                      style={{
                        color: theme.colors.colorsForegroundFgWhite,
                      }}
                      size="12"
                      strokeWidth={2}
                    />
                  </Box>
                }
              />
            }
            label={t("pages.analyticsPage.selectAll")}
          />
        </Box>
        {availableParameters.map((param, index) => (
          <Box
            key={param.value}
            sx={{
              display: "flex",
              alignItems: "center",
              p: `${theme.spacing.spacingSm} ${theme.spacing.spacingXl}`,
              maxWidth: "240px",
              ".MuiTypography-root": {
                ...theme.typography[".text-sm-medium"],
                color: theme.colors.colorsTextTextSecondary,
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected.includes(param.value)}
                  onChange={() => {
                    if (selected.includes(param.value)) {
                      const updatedArray = selected.filter(
                        (item) => item !== param.value
                      );
                      setSelected(updatedArray);
                    } else {
                      const updatedArray = [...selected, param.value];
                      setSelected(updatedArray);
                    }
                  }}
                  icon={<Box sx={baseCheckboxStyles} />}
                  checkedIcon={
                    <Box sx={selectedCheckboxStyles}>
                      <Check
                        style={{
                          color: theme.colors.colorsForegroundFgWhite,
                        }}
                        size="12"
                        strokeWidth={2}
                      />
                    </Box>
                  }
                />
              }
              label={param.label}
            />
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: theme.spacing.spacingLg,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          label={`${t("pages.analyticsPage.applyButton")}`}
          disabled={arraysAreEqual(selected, selectedParameters)}
          onClick={() => {
            setSelectedParameters(selected);
            closeDropdown();
          }}
        />
      </Box>
    </Box>
  );
}
