import { Box, List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useTheme from "../../theme/hooks/useTheme";

export interface ITabData {
  href: string;
  label: string;
}

export interface ITabsNavigationProps {
  tabs: Array<ITabData> | undefined;
}

export default function TabsNavigation({ tabs }: ITabsNavigationProps) {
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <List
        sx={{
          display: "flex",
          width: "100%",
          height: "44px",
          backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
          border: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
          borderRadius: "10px",
          padding: theme.spacing.spacingXs,
        }}
      >
        {tabs?.map((tab, index) => (
          <ListItem
            key={`tab-item-residential-installation-detail-${index}`}
            button
            component={RouterLink}
            to={tab.href}
            sx={{
              borderRadius: "10px",
              backgroundColor: currentPath.includes(tab.href.split('?')[0])
                ? theme.colors.colorsBackgroundBgPrimary
                : "initial",
              "&:hover": {
                backgroundColor: currentPath.includes(tab.href.split('?')[0])
                  ? theme.colors.colorsBackgroundBgPrimary
                  : theme.colors.colorsBackgroundBgSecondaryHover,
              },
              boxShadow: currentPath.includes(tab.href.split('?')[0])
                ? "0 1px 3px rgba(16, 24, 40, 0.06)"
                : "none",
            }}
          >
            <ListItemText
              sx={{ display: "flex", justifyContent: "center" }}
              primary={tab.label}
              primaryTypographyProps={{
                style: {
                  ...(theme.typography[".text-sm-semibold"] as any),
                  color: currentPath.includes(tab.href)
                    ? theme.colors.colorsTextTextSecondary
                    : theme.colors.colorsTextTextQuaternary,
                },
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
