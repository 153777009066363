import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  styled,
  Box,
  SxProps,
  Button,
} from "@mui/material";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import useTheme from "../../theme/hooks/useTheme";
import usePagination from "@mui/material/usePagination/usePagination";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight } from "untitledui-js-base";

export interface Data {
  [key: string]: any;
}

export interface Column {
  id: string;
  label: string;
  hidden?: boolean;
  minWidth?: number;
  maxWidth?: number;
  width?: any;
  align?: "right" | "left" | "center";
  disableSorting?: boolean;
  sortKey?: string;
}

export interface IPaginationData {
  totalCount: number;
  page: number;
  onPageChange: any;
}

interface TableProps {
  columns: Column[];
  rows: Data[];
  onRowClick?: (row: Data) => void;
  onSortClick?: (columnId: string, direction: "asc" | "desc") => void;
  disableSort?: boolean;
  infoTableHeadContent?: any;
  defaultSorting?: string;
  defaultOrderDirection?: string;
  paginationData?: IPaginationData;
}

const sortData = (
  array: Data[],
  comparator: (a: Data, b: Data) => number
): Data[] => {
  const stabilizedThis = array?.map(
    (el, index) => [el, index] as [Data, number]
  );
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
};

const CustomTableSortLabel = ({
  active,
  direction,
  onClick,
  children,
  theme,
}: any) => {
  const [hover, setHover] = useState(false);

  const IconComponent = () => {
    if (active) {
      return direction === "desc" ? (
        <KeyboardDoubleArrowDownOutlinedIcon
          sx={{ fontSize: "18px", ml: "5px" }}
        />
      ) : (
        <KeyboardDoubleArrowUpOutlinedIcon
          sx={{ fontSize: "18px", ml: "5px" }}
        />
      );
    } else {
      if (hover)
        return (
          <KeyboardDoubleArrowUpOutlinedIcon
            sx={{ opacity: ".5", fontSize: "18px", ml: "5px" }}
          />
        );
      return (
        <KeyboardDoubleArrowUpOutlinedIcon
          sx={{ color: "transparent", fontSize: "18px", ml: "5px" }}
        />
      );
    }
  };

  return (
    <TableSortLabel
      active={active}
      direction={direction}
      onClick={onClick}
      sx={{
        color: theme.colors.colorsTextTextTertiary,
      }}
      IconComponent={IconComponent}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
    </TableSortLabel>
  );
};

const SortableTable: React.FC<TableProps> = ({
  columns,
  rows,
  onRowClick,
  onSortClick,
  disableSort,
  infoTableHeadContent,
  defaultSorting,
  defaultOrderDirection,
  paginationData,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { items } = usePagination({
    count: paginationData?.totalCount,
    page: paginationData?.page,
    onChange: (event, page) => {
      paginationData?.onPageChange(event, page);
    },
  });

  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState<string>("");

  useLayoutEffect(() => {
    if (defaultSorting) {
      setValueToOrderBy(defaultSorting);
    }

    if (defaultOrderDirection) {
      setOrderDirection(defaultOrderDirection as "asc" | "desc");
    }
  }, []);

  const createSortHandler = (columnId: string) => () => {
    if (disableSort) return;

    const isAsc = valueToOrderBy === columnId && orderDirection === "asc";
    const newDirection = isAsc ? "desc" : "asc";

    setOrderDirection(newDirection);
    setValueToOrderBy(columnId);
    onSortClick?.(columnId, newDirection);
  };

  const sortComparator = (a: Data, b: Data) => {
    if (valueToOrderBy === "") return 0;

    const column = columns.find((col) => col.id === valueToOrderBy);
    const sortKey = column?.sortKey || valueToOrderBy;

    const aValue = a[sortKey];
    const bValue = b[sortKey];

    if (aValue < bValue) return orderDirection === "asc" ? -1 : 1;
    if (aValue > bValue) return orderDirection === "asc" ? 1 : -1;
    return 0;
  };

  const onRowClickHandler = (e: any, row: any) => {
    e.stopPropagation();
    onRowClick && onRowClick(row);
  };

  const sortedRows =
    disableSort || onSortClick ? rows : sortData(rows, sortComparator);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          fontSize: "12px",
          fontFamily: "Inter",
        }}
      >
        <TableHead>
          {infoTableHeadContent && <TableRow>{infoTableHeadContent}</TableRow>}
          <TableRow
            sx={{
              backgroundColor: "#F9FAFC",
              height: "44px",
            }}
          >
            {columns.map(
              (column) =>
                !column.hidden && (
                  <TableCell
                    sx={{
                      textTransform: "initial",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "8px 16px",
                      color: theme.colors.colorsTextTextTertiary,
                    }}
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      width: column.width,
                    }}
                  >
                    {disableSort || column.disableSorting ? (
                      column.label
                    ) : (
                      <CustomTableSortLabel
                        active={valueToOrderBy === column.id}
                        direction={
                          valueToOrderBy === column.id ? orderDirection : "asc"
                        }
                        onClick={createSortHandler(column.id)}
                        theme={theme}
                      >
                        {column.label}
                      </CustomTableSortLabel>
                    )}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows?.map((row, index) => (
            <TableRow
              sx={{
                cursor: !!onRowClick ? "pointer" : "initial",
                borderLeft: row.isRowHighlited
                  ? `4px solid ${
                      row.highlightColor ||
                      theme.colors.colorsForegroundFgWarningSecondary
                    }`
                  : "initial",
              }}
              hover={!!onRowClick}
              onClick={(e) => onRowClick && onRowClickHandler(e, row)}
              key={index}
            >
              {columns.map(
                (column) =>
                  !column.hidden && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        fontSize: "14px",
                        padding: "10px 16px",
                      }}
                    >
                      {row[column.id]}
                    </TableCell>
                  )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {paginationData && paginationData.totalCount !== 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: `${theme.spacing.spacingLg} ${theme.spacing.spacingXl}`,
          }}
        >
          <Box sx={{ display: "flex" }}>
            {items.map(({ page, type, selected, ...item }, index) => {
              let children = null;
              const buttonStyles: SxProps = {
                backgroundColor: "transparent",
                textTransform: "initial",
                border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
                borderRadius: 0,
                borderRight: "none",
                fontFamily: "Inter",
                color: theme.colors.colorsTextTextSecondary,
                padding: theme.spacing.spacingMd,
                maxHeight: "40px",
              };

              if (type === "start-ellipsis" || type === "end-ellipsis") {
                children = <Box sx={{ ...buttonStyles }}>"…"</Box>;
              } else if (type === "previous") {
                children = (
                  <Button
                    type="button"
                    style={{
                      fontWeight: selected ? "bold" : undefined,
                    }}
                    sx={{
                      ...buttonStyles,
                      borderRadius: "8px 0 0 8px",
                      padding: `${theme.spacing.spacingMd} ${theme.spacing.spacingXl}`,
                    }}
                    {...item}
                  >
                    <ArrowLeft
                      size="20"
                      style={{ marginRight: theme.spacing.spacingMd }}
                    />
                    {t("pagination.previousLabel")}
                  </Button>
                );
              } else if (type === "next") {
                children = (
                  <Button
                    type="button"
                    style={{
                      fontWeight: selected ? "bold" : undefined,
                    }}
                    sx={{
                      ...buttonStyles,
                      borderRight: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
                      borderRadius: "0 8px 8px 0",
                      padding: `${theme.spacing.spacingMd} ${theme.spacing.spacingXl}`,
                    }}
                    {...item}
                  >
                    {t("pagination.nextLabel")}
                    <ArrowRight
                      size="20"
                      style={{ marginLeft: theme.spacing.spacingMd }}
                    />
                  </Button>
                );
              } else if (type === "page") {
                children = (
                  <Button
                    type="button"
                    style={{
                      fontWeight: selected ? "bold" : undefined,
                    }}
                    sx={{
                      ...buttonStyles,
                      backgroundColor: selected
                        ? theme.colors.colorsBackgroundBgPrimaryHover
                        : "initial",
                      minWidth: "40px",
                      width: "40px",
                    }}
                    {...item}
                  >
                    {page}
                  </Button>
                );
              } else {
                children = (
                  <Button type="button" sx={{ ...buttonStyles }} {...item}>
                    {type}
                  </Button>
                );
              }

              return <Box key={index}>{children}</Box>;
            })}
          </Box>
        </Box>
      )}
    </TableContainer>
  );
};

export default SortableTable;
