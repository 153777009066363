import React, { useState, useEffect, useRef } from "react";
import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import { Box } from "@mui/material";
import useTheme from "../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { SearchLG } from "untitledui-js-base";

// const API_KEY = "AIzaSyC_Sni4YHxJpB1O3Chc9uNWzjfpBb9UBUg";

const AddressAutocompleteComponent = ({
  onAddressSelect,
  address,
  placeholder,
}: {
  onAddressSelect: (address: string) => void;
  address: string | null;
  placeholder: string;
}) => {
  const API_KEY = process.env.REACT_APP_ESCLOUD_GOOGLE_MAPS_API_KEY || "";
  const [selectedPlace, setSelectedPlace] = useState<
    google.maps.places.PlaceResult | null | undefined
  >(null);

  const [currentAddress, setCurrentAddress] = useState<string | null>("");

  useEffect(() => {
    setCurrentAddress(address);
  }, [address]);

  useEffect(() => {
    onAddressSelect(selectedPlace?.formatted_address as string);
  }, [selectedPlace]);

  return (
    <APIProvider
      apiKey={API_KEY}
      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
    >
      <PlaceAutocomplete
        onPlaceSelect={setSelectedPlace}
        address={currentAddress}
        placeholder={placeholder}
      />
    </APIProvider>
  );
};

interface PlaceAutocompleteProps {
  onPlaceSelect: (
    place: google.maps.places.PlaceResult | null | undefined
  ) => void;
  address: string | null;
  placeholder: string;
}

const PlaceAutocomplete = ({
  onPlaceSelect,
  address,
  placeholder,
}: PlaceAutocompleteProps) => {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  const [inputValue, setInputValue] = useState<string | null | undefined>("");
  const [previousSelectedAddress, setPreviousSelectedAddress] =
    useState<any>("");

  useEffect(() => {
    setInputValue(address);
    setPreviousSelectedAddress(address);
  }, [address]);

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  const onInputChange = (e: any) => {
    setInputValue(e.target.value);

    if (!e.target.value) {
      onPlaceSelect(undefined);
    }
  };

  const onInputBlur = () => {
    setInputValue(previousSelectedAddress);
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        input: {
          boxSizing: "border-box",
          height: "44px",
          width: "100%",
          padding: "0 15px 0 30px",
          borderRadius: theme.radius.radiusMd,
          border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
          fontFamily: `"Inter", "Roboto", "Arial", sans-serif`,
          fontSize: "1rem",
          lineHeight: "1.4375em",
          color: theme.colors.colorsTextTextPrimary,
          "&::placeholder": {
            color: theme.colors.colorsTextTextPlaceholder,
            opacity: ".5",
          },
          "&:hover": {
            borderColor: theme.colors.colorsTextTextPrimary,
          },
          "&:focus-visible": {
            outline: "-webkit-focus-ring-color auto 1px",
            outlineColor: "#6c6b6a",
          },
        },
      }}
    >
      <SearchLG
        style={{
          position: "absolute",
          top: "14px",
          left: "10px",
          color: theme.colors.colorsTextTextPlaceholder,
          opacity: ".5",
        }}
        size="16"
        strokeWidth={2}
      />
      <input
        // defaultValue={inputValue || ""}
        value={inputValue || ""}
        ref={inputRef}
        placeholder={placeholder}
        onChange={onInputChange}
        onBlur={onInputBlur}
      />
    </Box>
  );
};

export default AddressAutocompleteComponent;
